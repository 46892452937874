export const extractGAClientID = (input: string | null): string => {
  if (!input) {
    return ''
  }
  const matches = input.match(/([0-9]+\.[0-9]+)$/)
  return matches ? matches[0] : ''
}

export const extractGASessionID = (input: string | null): string => {
  if (!input) {
    return ''
  }
  return input.split('.')[2]
}
